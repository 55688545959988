<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title"><translate>Add credit card</translate></h4>
        </div>

        <div class="card-body">
          <CreditCardForm
            @success="returnToCreditCards"
            @cancel="returnToCreditCards"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CreditCardForm from "@/views/Boutique/components/CreditCardForm";
export default {
  components: { CreditCardForm },
  methods: {
    returnToCreditCards() {
      this.$router.push({ name: "credit-cards" });
    }
  }
};
</script>

<template>
  <div>
    <stripe-element-card
      :disabled="disabled"
      ref="stripe-card"
      :pk="pk"
      :locale="lg"
      @token="tokenCreated"
      @error="handleError"
      @loading="handleLoading"
    />
    <FormInput
      :disabled="disabled"
      type="text"
      field="card_holder"
      :label="$gettext('Card holder')"
      v-model="card_holder"
    ></FormInput>
    <b-button
      v-show="cancellable"
      variant="danger"
      @click.prevent="handleCancel"
      :disabled="disabled"
      ><translate>Cancel</translate></b-button
    >
    <b-button variant="info" @click.prevent="handleSubmit" :disabled="disabled"
      ><translate>Add card</translate></b-button
    >
  </div>
</template>

<script>
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import { mapActions, mapGetters, mapMutations } from "vuex";
import FormInput from "@/components/FormGroups/FormInput";

export default {
  props: {
    cancellable: {
      type: Boolean,
      default: true
    }
  },
  components: {
    FormInput,
    StripeElementCard
  },
  data: () => ({
    pk: process.env.VUE_APP_STRIPE_PK,
    card_holder: "",
    isPosting: false,
    isLoading: false
  }),
  computed: {
    ...mapGetters("me", ["lg"]),
    disabled() {
      return this.isLoading || this.isPosting;
    }
  },
  methods: {
    ...mapActions("creditCards", ["create"]),
    ...mapMutations("form", ["setErrors"]),
    handleSubmit() {
      this.$refs["stripe-card"].submit();
    },
    handleCancel() {
      this.$emit("cancel");
    },
    tokenCreated(token) {
      this.isPosting = true;
      this.create({ token, card_holder: this.card_holder })
        .then(() => {
          this.$emit("success");
          this.$refs["stripe-card"].clear();
          this.card_holder = "";
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.setErrors(error.response.data);
          }
        })
        .finally(() => (this.isPosting = false));
    },
    handleError() {
      this.isLoading = false;
    },
    handleLoading(loadingState) {
      this.isLoading = loadingState;
    }
  },
  watch: {
    isPosting(value) {
      this.$refs["stripe-card"].update({
        disabled: value
      });
    }
  }
};
</script>
